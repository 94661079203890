import React, { useEffect, useState } from "react";
import "./RefertiOnline.css";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { url_getDocuments } from "../lib/url";
import { Card } from "primereact/card";
import { Button } from "primereact/button"; 
import JSZip from "jszip";                  
import { saveAs } from "file-saver";        

interface Report {
  pdfBase64: string;
  episodeId: string;
  Date: string;
  typeId: string;
  Name: string;
  workareasString?: string; // Nuovo campo per "Settori"
}

const RefertiOnline: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Recupera token e codice fiscale dallo stato globale
  const token = useSelector((state: RootState) => state.user.token);
  const username = useSelector((state: RootState) => state.user.codiceFiscale);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (reports.length > 0) {
          // Evita di ricaricare se la lista dei referti è già popolata
          return;
        }

        setLoading(true);

        if (!username) {
          setError("Utente non autenticato. Effettua il login per visualizzare i referti.");
          setLoading(false);
          return;
        }

        const response = await fetch(`${url_getDocuments}?username=${encodeURIComponent(username)}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            // Nessun referto
            setReports([]);
            setLoading(false);
            return;
          }

          const contentType = response.headers.get("content-type");
          if (contentType?.includes("text/html")) {
            throw new Error("Errore nel caricamento: Risorsa non trovata.");
          }

          const errorData = await response.json();
          throw new Error(errorData?.Messaggio || "Errore durante il caricamento dei referti.");
        }

        const data = await response.json();
        setReports(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Errore sconosciuto.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [username, token, reports.length]);

  // Funzione per visualizzare un PDF in una nuova tab
  const handleViewReport = (pdfBase64: string) => {
    const byteCharacters = atob(pdfBase64);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  // Funzione per scaricare singolo PDF
  const handleDownloadReport = (report: Report) => {
    const pdfBase64 = report.pdfBase64;
    const byteCharacters = atob(pdfBase64);
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const epId = report.episodeId || "episode";
    const sectors = report.workareasString?.replace(/\s+/g, "_") || "N/A";
    const dateStr = report.Date?.replace(/\//g, "-") || "data";
    const fileName = `${epId}_${sectors}_${dateStr}.pdf`;

    saveAs(blob, fileName);
  };

  // Funzione per scaricare TUTTI i referti di un gruppo in un unico .zip
  const handleDownloadAllReportsZip = (reportsGroup: Report[], episodeId: string) => {
    const zip = new JSZip();

    reportsGroup.forEach((report, index) => {
      const pdfBase64 = report.pdfBase64;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);

      const sectors = report.workareasString?.replace(/\s+/g, "_") || "N/A";
      const dateStr = report.Date?.replace(/\//g, "-") || `report_${index}`;
      const filename = `${episodeId}_${sectors}_${dateStr}.pdf`;

      zip.file(filename, byteArray);
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `referti_episode_${episodeId}.zip`);
    });
  };

  if (loading) {
    return <p className="loading-message">Caricamento in corso...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (reports.length === 0) {
    return (
      <div className="no-reports-message">
        <p>Non ci sono referti disponibili.</p>
        <p>Se hai bisogno di assistenza, contatta il centro.</p>
      </div>
    );
  }

  const groupedByEpisode = reports.reduce((acc, report) => {
    if (!acc[report.episodeId]) {
      acc[report.episodeId] = [];
    }
    acc[report.episodeId].push(report);
    return acc;
  }, {} as Record<string, Report[]>);

  return (
    <div className="referti-container">
      <h2>I tuoi referti</h2>

      <div className="referti-list">
        {Object.entries(groupedByEpisode).map(([episodeId, group]) => {
          const hasMultiple = group.length > 1;

          return (
            <div className="referto-group" key={episodeId}>
              <Card className="referto-card">
                <h3>Accettazione N. {episodeId}</h3>

                {group.map((report, index) => (
                  <div className="singolo-referto" key={index}>
                    <div className="singolo-referto-div">
                      <i className="pi pi-calendar" />
                      <p>Data: {report.Date || "Non disponibile"}</p>
                    </div>
                    <div className="singolo-referto-div">
                      <i className="pi pi-file" />
                      <p>Descrizione: {report.typeId}</p>
                    </div>
                    <div className="singolo-referto-div">
                      <i className="pi pi-list" />
                      <p>Settori: {report.workareasString || "N/A"}</p>
                    </div>

                    <div className="referti-btn-div">
                      <Button
                        label="Visualizza"
                        icon="pi pi-eye"
                        className="appuntamenti-btn view-btn"
                        onClick={() => handleViewReport(report.pdfBase64)}
                      />
                      <Button
                        label="Scarica"
                        icon="pi pi-download"
                        className="appuntamenti-btn download-btn"
                        onClick={() => handleDownloadReport(report)}
                      />
                    </div>
                    <hr />
                  </div>
                ))}

                {hasMultiple && (
                  <div className="referti-btn-div">
                    <Button
                      label="Scarica tutti"
                      icon="pi pi-download"
                      className="appuntamenti-btn download-all-btn"
                      onClick={() => handleDownloadAllReportsZip(group, episodeId)}
                    />
                  </div>
                )}
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RefertiOnline;
