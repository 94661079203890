// src/Redux/reducer/globalDataSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Activity } from "../../lib/types";

export interface Report {
  pdfBase64: string;
  episodeId: string;
  Date: string;
  typeId: string;
  Name: string;
  workareasString?: string;
}

interface GlobalDataState {
  globalReports: Report[];       // i referti
  globalActivities: Activity[];  // le prestazioni
  isActivitiesLoaded: boolean;   // flag per capire se abbiamo già caricato
  reportsLoading: boolean;       // <--- nuovo flag di "caricamento referti"
}

const initialState: GlobalDataState = {
  globalReports: [],
  globalActivities: [],
  isActivitiesLoaded: false,
  reportsLoading: false, // <--- inizialmente false
};

export const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    // Imposta i referti
    setGlobalReports: (state, action: PayloadAction<Report[]>) => {
      state.globalReports = action.payload;
    },
    // Imposta le prestazioni
    setGlobalActivities: (state, action: PayloadAction<Activity[]>) => {
      state.globalActivities = action.payload;
      state.isActivitiesLoaded = true;
    },
    // <--- Nuova azione per flag di loading referti
    setGlobalReportsLoading: (state, action: PayloadAction<boolean>) => {
      state.reportsLoading = action.payload;
    },
  },
});

export const {
  setGlobalReports,
  setGlobalActivities,
  setGlobalReportsLoading, // <--- Esporta anche la nuova azione
} = globalDataSlice.actions;

export default globalDataSlice.reducer;
