import React, { useEffect, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { MenuItem } from "primereact/menuitem";
import Prenotazioni from "./Prenotazioni";
import Login from "./Login";
import Appuntamenti from "./Appuntamenti";
import RefertiOnline from "./RefertiOnline";
import Account from "./Account";
import "./MainPage.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import { url_getActivities, url_patient_info, url_token } from "../lib/url";
import { setPatientInfo, setToken } from "../Redux/reducer/userSlice";
import Registration from "./Registration";
import { popHistory, pushHistory } from "../Redux/reducer/historySlice";
import { Activity, GroupedActivity } from "../lib/types";
import { showPrenotazioni, showAppuntamenti, showRefertiOnline } from "../lib/config";

const MainPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<GroupedActivity[]>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.user.token);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const codiceFiscale = useSelector((state: RootState) => state.user.codiceFiscale);
  const history = useSelector((state: RootState) => state.history.history);

  // Costruisce dinamicamente gli elementi del menu in base alle variabili di configurazione
  const items: MenuItem[] = [
    ...(showPrenotazioni ? [{ label: "Prenotazioni", icon: "pi pi-fw pi-calendar" }] : []),
    ...(showAppuntamenti ? [{ label: "Appuntamenti", icon: "pi pi-fw pi-list-check" }] : []),
    ...(showRefertiOnline && isLoggedIn ? [{ label: "Referti Online", icon: "pi pi-fw pi-file-pdf" }] : []),
    { label: isLoggedIn ? "Account" : "Login", icon: isLoggedIn ? "pi pi-fw pi-user" : "pi pi-fw pi-sign-in" },
    ...(isLoggedIn ? [] : [{ label: "Registrazione", icon: "pi pi-fw pi-user-plus" }]),
  ];

  const clientId = "client";
  const tokenSecret = "754fee8a10713ae8c335434a5f859ea1b00d17ee750d0c51957e9463d7776d0f";

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(url_token, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ClientId: clientId,
            ClientSecret: tokenSecret,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(setToken(data.accessToken));
      } catch (error) {
        console.error("Fetch error:", error instanceof Error ? error.message : "Unexpected error");
      }
    };

    fetchToken();
  }, [dispatch]);

  useEffect(() => {
    const fetchPatientInfo = async () => {
      if (isLoggedIn && codiceFiscale) {
        try {
          const response = await fetch(`${url_patient_info}${codiceFiscale}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          dispatch(setPatientInfo(data));
        } catch (error) {
          console.error("Fetch error:", error instanceof Error ? error.message : "Unexpected error");
        }
      }
    };

    fetchPatientInfo();
  }, [isLoggedIn, codiceFiscale, dispatch]);

useEffect(() => {
  const fetchActivities = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 60000); // Timeout aumentato a 20 secondi

    try {
      setLoading(true);
      const response = await fetch(url_getActivities, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: controller.signal,
      });

      clearTimeout(timeoutId); // Cancella il timeout se la risposta arriva prima

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: { result: string; msg: string; return: Activity[] } = await response.json();
      setActivities(data.return);

      // Raggruppa le attività per gruppo
      const grouped: { [key: string]: GroupedActivity } = {};
      data.return.forEach(activity => {
        if (!grouped[activity.group]) {
          grouped[activity.group] = { label: activity.group, items: [] };
        }
        grouped[activity.group].items.push(activity);
      });

      setGroupedActivities(Object.values(grouped));
      setLoading(false);
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        console.error("La richiesta è stata annullata a causa del timeout.");
      } else if (error instanceof Error) {
        console.error("Errore durante il fetch delle attività:", error.message);
      } else {
        console.error("Errore sconosciuto:", error);
      }
    }
  };

  fetchActivities();
}, [token]);


  const handleTabChange = (index: number) => {
    dispatch(pushHistory(activeIndex));
    setActiveIndex(index);
  };

  const handleBack = () => {
    if (history.length > 0) {
      const previousIndex = history[history.length - 1];
      dispatch(popHistory());
      setActiveIndex(previousIndex);
    }
  };

  return (
    <div className="tabmenu-div">
      <TabMenu
        model={items}
        className="tabmenu"
        activeIndex={activeIndex}
        onTabChange={e => handleTabChange(e.index)}
      />
      <i className="pi pi-arrow-left back-icon" onClick={handleBack} style={{ cursor: "pointer", margin: "10px" }}></i>
      <div className="content">
        {showPrenotazioni && activeIndex === items.findIndex(item => item.label === "Prenotazioni") && (
          <Prenotazioni
            setActiveIndex={setActiveIndex}
            loading={loading}
            activities={activities}
            groupedActivities={groupedActivities}
          />
        )}
        {showAppuntamenti && activeIndex === items.findIndex(item => item.label === "Appuntamenti") && (
          <Appuntamenti
            setActiveIndex={setActiveIndex}
            activities={activities}
            groupedActivities={groupedActivities}
            loading={loading}
          />
        )}
        {isLoggedIn &&
          showRefertiOnline &&
          activeIndex === items.findIndex(item => item.label === "Referti Online") && <RefertiOnline />}
        {activeIndex === items.findIndex(item => item.label === "Login") && (
          <Login setActiveIndex={setActiveIndex} />
        )}
        {activeIndex === items.findIndex(item => item.label === "Registrazione") && (
          <Registration setActiveIndex={setActiveIndex} />
        )}
        {activeIndex === items.findIndex(item => item.label === "Account") && <Account />}
      </div>
    </div>
  );
};

export default MainPage;
